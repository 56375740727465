import {
  faBirthdayCake,
  faGift,
  faGlassCheers,
  faHeadset,
  faUserTie,
  faUtensils,
  
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import GlobalBannerImage from "../../components/GlobalComponents/GlobalBannerImage";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServicesPage = () => {

 
  const [returantServices, setReturantServices] = useState([
    {
      icon: <FontAwesomeIcon icon={faGift} />,
      name: "Food Delivery",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum ",
    },
    {
      icon: <FontAwesomeIcon icon={faGlassCheers} />,
      name: "Private Events",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum",
    },
    {
      icon: <FontAwesomeIcon icon={faBirthdayCake} />,
      name: "Special Desserts",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum ",
    },
    {
      icon: <FontAwesomeIcon icon={faUserTie} />,
      name: "Master Chefs",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum ",
    },
    {
      icon: <FontAwesomeIcon icon={faUtensils}/>,
      name: "Qquality Foods",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum ",
    },
    {
      icon: <FontAwesomeIcon icon={faHeadset} />,
      name: "24/7 Service",
      discription:
        "Duo at tibique conclusionemque, nam modo ancillae tacimates et, cu vim omnes phaedrum ",
    },
  ]);

  const [specialized, setSpecialized] = useState([
    {
      tittle: "Celebrate Unforgettable Birthday Parties with Us!",
      image: require("../../assets/images/services/birthdayimage.jpg"),
      description:
        "Are you ready to turn your birthday celebration into an unforgettable event? Look no further! At Md.Ali, we specialize in creating magical moments for birthday parties that will leave you and your guests with cherished memories.",
      approach: "Creative approach to every Menu",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "Every birthday is unique, and we believe the celebration should reflect that",
      approachTittle2:
        "Our catering services offer a variety of options, from mouth-watering appetizers to decadent birthday cakes that will satisfy every palate.",
      approachTittle3:
        " Keep the party alive with our entertainment options. From live music to interactive games, we have something for guests of all ages",
    },
    {
      tittle: "Elegance and Tradition Unite: Celebrate Your Walima Ceremony with Us",
      image: require("../../assets/images/services/walima.jpg"),
      description:
        "At Md.Ali, we understand the significance of a Walima ceremony in uniting two lives in a celebration of love and tradition. Allow us to bring the essence of elegance and joy to your special day.",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "We honor the cultural significance of the Walima ceremony and ensure that every aspect of our services reflects the traditions and values of your celebration.",
      approachTittle2:
        "Our experienced decorators can create a setting that reflects your style and preferences.",
      approachTittle3:
        "We focus on the details, allowing you to savor every moment of your special day.",
    },
    {
      tittle: "Elevate Your Corporate Events with Our Professional Services",
      // image: require("../../assets/images/servicepage/aqiqa.jpg"),
      image: require("../../assets/images/services/corporation-event.jpg"),
      description:
        "Planning a corporate event that leaves a lasting impression requires attention to detail and a touch of sophistication. At Md.Ali, we specialize in curating seamless and memorable corporate events tailored to meet the unique needs of your organization.",
      logo: require("../../assets/images/icons8-tick-96.png"),
      approachTittle1:
        "We understand the importance of corporate events in achieving your business objectives. .",
      approachTittle2:
        "Impress your clients and colleagues with our modern and fully equipped event spaces.",
      approachTittle3:
        "Elevate your event with our gourmet catering services. ",
    },
  ]);



  return (
    <div className="main-container">
      <GlobalBannerImage />
      {/* <div className="container resturant-services mt-5 mx-5 px-5">
        <Row>
          {returantServices.map((value) => {
            return (
              <>
                <Col md={4} className="mt-5">
                  <div className="service">
                    <span className="font-icon-color">{value.icon}</span>
                    <h2>{value.name}</h2>
                    <p>{value.discription}</p>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div> */}

<div className="container resturant-services mt-5 ps-3">
      <Row>
        {returantServices.map((value, index) => (
          <Col key={index} xs={12} sm={6} md={4} className="mb-4">
            <div className="service text-center">
              <span className="font-icon-color">{value.icon}</span>
              <h2>{value.name}</h2>
              <p>{value.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
      {/* <div className="chefs-services mt-5">
        <div className="about-chefs-service">
          <span>A LA CARTE</span>
          <h1>CHEF'S SPECIALS</h1>
          <p>
            Lorem ipsum dolor sit amet, sea cibo laoreet omnesque ei, usu eu
            dicit perpetua assueverit. <br /> Has ut cibo gubergren, qui et
            laoreet appareat. Ea usu posse oratio, vel tritani equidem <br />{" "}
            propriae ex. Sit ferri pertinax ei, et eam esse duis.
          </p>
          <div className="menu-btn">
            <Link to={'/menu'}>
            <button>VIEW OUR MENU</button>
            </Link>
          </div>
        </div>
      </div> */}
      <div className="container table-reserve mt-5">

      {specialized.map((item) => {
          return (
            <>
              <h1 className="mb-5 mt-3 services-title">{item.tittle}</h1>
              <Row>
                <Col md={6}>
                  <div className="services-description">
                    <LazyLoadImage src={item.image} alt="services" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="desc-popular">{item.description}</div>
                </Col>

                <div className="mt-3 comment-desc">{item.approach}</div>
                <div className="d-flex align-item-center  ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 approach-title  ">
                    {item.approachTittle1}
                  </div>
                </div>
                <div className="d-flex align-item-center  ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 approach-title">
                    {item.approachTittle2}
                  </div>
                </div>
                <div className="d-flex align-item-center ">
                  <div className="comments-logo">
                    <LazyLoadImage src={item.logo} />
                  </div>
                  <div className="ms-3 mt-3 mb-5 approach-title">
                    {item.approachTittle3}
                  </div>
                </div>
              </Row>
            </>
          );
        })}
        {/* <Row>
          <Col md={12} className="">
            <div className="service-reseve-table">
              <h1>RESERVE A TABLE</h1>
              <p>
                Lorem ipsum dolor sit amet, sea cibo laoreet omnesque ei, usu eu
                dicit perpetua assueverit. Has ut cibo gubergren, qui et laoreet
                appareat. Ea usu posse oratio, vel tritani equidem propriae ex.
                Sit ferri pertinax ei, et eam esse duis.
              </p>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default ServicesPage;
