import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import HotelWay from "../../assets/images/BackgroundImages/about-9-3.jpg";
import centerLogo from "../../assets/images/png/taste-food-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import HomeScreenImageBanner from "../../components/HomeScreenComponents/HomeScreenBannerImage";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Link } from "react-router-dom";

const HomePage = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);



  const [homeMenuStarter, setHomeMenuStarter] = useState([
    {
      image: require("../../assets/images/demo/starter/chicken-angara-kabab.jpg"),
      name: "Chicken Angara Kabab",
      // dots:
      price: "₹4.00",
      itemOptions: "Chicken / Olive Oil / Angara",
    },
    {
      image: require("../../assets/images/demo/starter/spicy-garlic-chicken-wings.jpg"),
      name: "Spicy Garlic Wings ",
      // dots:
      price: "₹7.00",
      itemOptions: "Fresh Chicken / Oive Oil / Garlic Wings",
    },
    {
      image: require("../../assets/images/demo/starter/prawn-ghee-roast.jpg"),
      name: "Prawn Ghee Roast",
      // dots:
      price: "₹5.00",
      itemOptions: "Prawn / Spicy / Roast",
    },
    {
      image: require("../../assets/images/demo/starter/Chicken-Boti-Kabab.webp"),
      name: "Chicken Boti Kabab",
      // dots:
      price: "₹9.00",
      itemOptions: "Chicken / Oil / Garlic",
    },
  ]);

  const [homeMainCources, setHomeMainCources] = useState([
    {
      image: require("../../assets/images/demo/starter/Fish-tikka-masala-recipe.webp"),
      name: "Best Fish Tikka Masala Recipe with Salmon",
      // dots:
      price: "₹21.00",
      itemOptions: "4 Fish, olive oil, garlic, onion",
    },
    {
      image: require("../../assets/images/demo/main-course/Chicken Curry.png"),
      name: "Chicken Curry",
      // dots:
      price: "₹20.00",
      itemOptions: "Chicken, Curry, black pepper",
    },
    {
      image: require("../../assets/images/demo/main-course/Mutton Curry.webp"),
      name: "Mutton Curry",
      // dots:
      price: "₹19.00",
      itemOptions: "500 grams Mutton, oil",
    },
    {
      image: require("../../assets/images/demo/main-course/Mutton Korma.webp"),
      name: "Mutton Korma",
      // dots:
      price: "₹12.00",
      itemOptions: "Beef, garlic, sesame oil, vinegar",
    },
    {
      image: require("../../assets/images/demo/main-course/chicken-65.webp"),
      name: "Chicken 65",
      // dots:
      price: "₹12.00",
      itemOptions: "Chicken, cherry tomatoes, olive oil",
    },
  ]);

  const [homeMenuSeafood, setHomeMenuSeafood] = useState([
    {
      image: require("../../assets/images/demo/seafood/Goan Prawn Curry With Raw Mango.jpg"),
      name: "Goan Prawn Curry With Raw Mango",
      // dots:
      price: "₹4.00",
      itemOptions: "Seafood / Olive Oil / Mango",
    },
    {
      image: require("../../assets/images/demo/seafood/butter-garlic-crabs.avif"),
      name: "Butter Garlic Crab",
      // dots:
      price: "₹7.00",
      itemOptions: "Fresh Seafood / Oive Oil / Crab",
    },
    {
      image: require("../../assets/images/demo/starter/prawn-ghee-roast.jpg"),
      name: "Prawn Ghee Roast",
      // dots:
      price: "₹5.00",
      itemOptions: "Prawn / Spicy / Roast",
    },
    {
      image: require("../../assets/images/demo/starter/Chicken-Boti-Kabab.webp"),
      name: "Chicken Boti Kabab",
      // dots:
      price: "₹9.00",
      itemOptions: "Chicken / Oil / Garlic",
    },
  ]);

  const [homeMenuDesert, setHomeMenuDesert] = useState([
    {
      image: require("../../assets/images/demo/starter/chicken-angara-kabab.jpg"),
      name: "Chicken Angara Kabab",
      // dots:
      price: "₹4.00",
      itemOptions: "Chicken / Olive Oil / Angara",
    },
    {
      image: require("../../assets/images/demo/starter/spicy-garlic-chicken-wings.jpg"),
      name: "Spicy Garlic Wings ",
      // dots:
      price: "₹7.00",
      itemOptions: "Fresh Chicken / Oive Oil / Garlic Wings",
    },
    {
      image: require("../../assets/images/demo/starter/prawn-ghee-roast.jpg"),
      name: "Prawn Ghee Roast",
      // dots:
      price: "₹5.00",
      itemOptions: "Prawn / Spicy / Roast",
    },
    {
      image: require("../../assets/images/demo/starter/Chicken-Boti-Kabab.webp"),
      name: "Chicken Boti Kabab",
      // dots:
      price: "₹9.00",
      itemOptions: "Chicken / Oil / Garlic",
    },
  ]);

  const [openClosedToggel1, setOpenClosedToggel1] = useState(false);
  const [openClosedToggel2, setOpenClosedToggel2] = useState(false);
  const [openClosedToggel3, setOpenClosedToggel3] = useState(false);
  const [openClosedToggel4, setOpenClosedToggel4] = useState(false);

  const ToggelVisibilty1 = () => {
    setOpenClosedToggel1(!openClosedToggel1);
  };
  const ToggelVisibilty2 = () => {
    setOpenClosedToggel2(!openClosedToggel2);
  };
  const ToggelVisibilty3 = () => {
    setOpenClosedToggel3(!openClosedToggel3);
  };
  const ToggelVisibilty4 = () => {
    setOpenClosedToggel4(!openClosedToggel4);
  };

  return (
    <>
      <div>
        <HomeScreenImageBanner />

        {/* <FreshFoodServiceCard /> */}

        <div className="container mb-5 pt-5 px-4">
          <Row className="align-items-center">
            <Col md={6} sm={12} className="mb-4 mb-md-0">
              <div>HISTORY</div>
              <h1>About Us</h1>

              {/* <p>
                Welcome to Dina, a modern restaurant with a focus on premium
                food tastes
              </p>
              <p className="pt-5">
                We invite you to celebrate our restaurant's delicious recipes
                whether you are here for a business lunch or dinner. Discover
                new tastes and inspired recipes from all over the world.
              </p> */}
              <p>
                Welcome to <b> Mohd Ali's </b> , a culinary haven where passion meets palates. Our story is one of devotion to the art of food, rooted in a rich culinary heritage. At <b> Mohd Ali's </b>, we strive to create an unforgettable dining experience, blending tradition with innovation. From our kitchen to your table, every dish is crafted with love and precision, reflecting our commitment to excellence.
              </p>

              <div className="find-btn pt-4">
                <Link to={"/about"}>
                  <button >FIND MORE</button>
                </Link>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="hotel-image">
                <LazyLoadImage src={HotelWay} alt="Hotel Way" />
              </div>
            </Col>
          </Row>
        </div>


        {/* <div className="speciality-hotel mb-5">
          <div className="center-img d-flex justify-content-center align-items-center">
            <LazyLoadImage src={centerLogo} alt="" />
          </div>
        </div> */}

        <div>
          <div className="container">
            <div className="menu">
              <span>DISCOVER</span>
              <h1>OUR MENU</h1>
              <p className="px-4">
                Explore texture, color and of course the ultimate tastes with
                our menu of the season. All the ingredients are fresh and
                carefully selected by our chefs. <br /> Enjoy an extraordinary
                dinning experience.
              </p>
            </div>

            <div className={`accordion ${openClosedToggel1 ? "open" : ""}`}>
              <div className="accordion-header" onClick={ToggelVisibilty1}>
                <div className="drop-down-container">
                  <FontAwesomeIcon
                    className="rc-accordion-icon"
                    icon={faChevronDown}
                  />
                  <h1>STARTERS</h1>
                </div>
              </div>

              <div className="accordion-content menu-lists">
                <Row>
                  {homeMenuStarter.map((value) => {
                    return (
                      <>
                        <Col md={6} className="mb-3">
                          <div className="item-1">
                            <div className="tomato-img">
                              <LazyLoadImage src={value.image} alt="" />
                            </div>
                            <div className="about-item ps-2">
                              <div className="item-name-price d-flex justify-content-between">
                                <span className="name">{value.name}</span>
                                <span className="menu-dots"></span>
                                <span className="price">{value.price}</span>
                              </div>
                              <div className="itemOptions">
                                <span>{value.itemOptions}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>

            <div className={`accordion ${openClosedToggel2 ? "open" : ""}`}>
              <div className="accordion-header" onClick={ToggelVisibilty2}>
                <div className="drop-down-container">
                  <FontAwesomeIcon
                    className="rc-accordion-icon"
                    icon={faChevronDown}
                  />
                  <h1>MAIN COURSES</h1>
                </div>
              </div>

              <div className="accordion-content menu-lists">
                <Row>
                  {homeMainCources.map((value) => {
                    return (
                      <>
                        <Col md={6} className="mb-3">
                          <div className="item-1">
                            <div className="tomato-img">
                              <LazyLoadImage src={value.image} alt="" />
                            </div>
                            <div className="about-item ps-2">
                              <div className="item-name-price d-flex justify-content-between">
                                <span className="name">{value.name}</span>
                                <span className="menu-dots"></span>
                                <span className="price">{value.price}</span>
                              </div>
                              <div className="itemOptions">
                                <span>{value.itemOptions}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>
            <div className={`accordion ${openClosedToggel3 ? "open" : ""}`}>
              <div className="accordion-header" onClick={ToggelVisibilty3}>
                <div className="drop-down-container">
                  <FontAwesomeIcon
                    className="rc-accordion-icon"
                    icon={faChevronDown}
                  />
                  <h1>SEAFOOD</h1>
                </div>
              </div>

              <div className="accordion-content menu-lists">
                <Row>
                  {homeMenuSeafood.map((value) => {
                    return (
                      <>
                        <Col md={6} className="mb-3">
                          <div className="item-1">
                            <div className="tomato-img">
                              <LazyLoadImage src={value.image} alt="" />
                            </div>
                            <div className="about-item ps-2">
                              <div className="item-name-price d-flex justify-content-between">
                                <span className="name">{value.name}</span>
                                <span className="menu-dots"></span>
                                <span className="price">{value.price}</span>
                              </div>
                              <div className="itemOptions">
                                <span>{value.itemOptions}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>
            <div className={`accordion ${openClosedToggel4 ? "open" : ""}`}>
              <div className="accordion-header" onClick={ToggelVisibilty4}>
                <div className="drop-down-container">
                  <FontAwesomeIcon
                    className="rc-accordion-icon"
                    icon={faChevronDown}
                  />
                  <h1>DESERT</h1>
                </div>
              </div>

              <div className="accordion-content menu-lists">
                <Row>
                  {homeMenuDesert.map((value) => {
                    return (
                      <>
                        <Col md={6} className="mb-3">
                          <div className="item-1">
                            <div className="tomato-img">
                              <LazyLoadImage src={value.image} alt="" />
                            </div>
                            <div className="about-item ps-2">
                              <div className="item-name-price d-flex justify-content-between">
                                <span className="name">{value.name}</span>
                                <span className="menu-dots"></span>
                                <span className="price">{value.price}</span>
                              </div>
                              <div className="itemOptions">
                                <span>{value.itemOptions}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>

            <div className="view-btn d-flex justify-content-center pt-4">
              <Link to={"/menu"}>
                <button>VIEW MENU</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="reserve-hotel mt-5">
          <div className="hotel-timing">
            <div className="table-book">
              <span>RESERVE A TABLE</span>
              <h1>OPEN HOURS</h1>
            </div>
            <div className="reserving-time pt-4 pb-5">
              <span>MONDAY - FRIDAY: 6 PM - 4 AM</span>
              <span className="pt-4">SATURDAY - SUNDAY: 6 PM - 4 AM</span>
            </div>
            <div className="book-btn pt-4">
              <Link to={"/reservation"}>
                <button>BOOK NOW</button>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="experience-wine">
          <div className="container mt-5 mb-5">
            <Row>
              <Col md={6} className="d-flex justify-content-end">
                <div className="wine-ex-image">
                  <LazyLoadImage src={WineExperience} alt="" />
                </div>
              </Col>
              <Col md={6}>
                <div className="wine-about pe-5">
                  <div className="exp mx-auto d-flex flex-column align-items-end">
                    <span>EXPERIENCE</span>
                    <h1>WINE</h1>
                  </div>
                  <span className="mt-4 text-end">
                    A unique dinner works best with wine pairing
                  </span>
                  <p className="pt-5 text-end">
                    You can choose from a variety of styles, light and easy
                    drinking, fresh and aromatic white wines or you could try
                    intense and elegant red wines. Creative cuisine involves
                    sophisticated wines.
                  </p>
                  <p className="text-end">
                    Vestibulum eleifend gravida neque a bibendum. Vivamus
                    viverra velit non cursus elementum. Donec sit amet posuere
                    ipsum. Mauris rutrum sagittis sapien text link. In vitae
                    ipsum eleifend, auctor turpis in, vestibulum dui. Ut
                    vestibulum, lorem id eleifend mollis, urna augue imperdiet
                    ante, vitae aliquam turpis mauris eget nisi.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div> */}
        <div className="food-drinks d-flex flex-column align-items-center justify-content-center mt-5">
          <div className="visit  d-flex flex-column align-items-center">
            <span>VISIT US</span>
            <h1>GOOD FOOD AND DRINKS</h1>
          </div>
          <div className="about-visit text-center pt-4">
            <p>
              Indulge in culinary artistry at <b>[Mohammad Ali Dhaba].</b>  Immerse your palate  <br />in exquisite flavors, from tantalizing dishes to handcrafted drinks, promising a gastronomic journey like no other.
            </p>
          </div>
          <div className="book-btn pt-4">
            <Link to={"/reservation"}>
              <button>BOOK NOW</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
