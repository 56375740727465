import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Button, Form, Modal } from "react-bootstrap"
// import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuCardImage = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    setModalOpen(true);
    console.log("card clicked", index);
  };
  const handleCloseModal = () => {
    setSelectedCardIndex(null);
    setModalOpen(false);
  };
  const [menucarddata, setMenuCardData] = useState([
    {
      downloadLink: '#',
      image: require("../assets/images/menu/bharat-dhaba.jpg"),
      name: "Md.Ali Bharat",
      childImage: [
        require("../assets/images/MdaliBharatDhaba/Biryani-Chilli.jpg"),
        require("../assets/images/MdaliBharatDhaba/Chicken-Handi.jpg"),
        require("../assets/images/MdaliBharatDhaba/Chicken-chaska-gravy.jpg"),
        require("../assets/images/MdaliBharatDhaba/Dum-biryani.jpg"),
        require("../assets/images/MdaliBharatDhaba/Maratha-kabab.jpg"),
      ],
      childImageNames: [
        "Chciken Lapeta",
        "Chicken Nawabi Handi",
        "Dhaba Chilli",
        "Irani Dry",
        "Maratha Kabab",
      ],
      info: "Raspberries, blackberries, blueberries, graham cracker crumbs",
      price: "$12.00 / $20.00",
    },
    {
      downloadLink: '#',
      image: require("../assets/images/menu/nizampura.jpg"),
      name: "Md.Ali Nizampura",
      childImage: [
        require("../assets/images/MdaliNizampura/BUTTER-CHICKEN.jpg"),
        require("../assets/images/MdaliNizampura/CHICKEN-FRIED-RICE.jpg"),
        require("../assets/images/MdaliNizampura/CHICKEN-TANDOORI.jpg"),
        require("../assets/images/MdaliNizampura/DHABA-CHILLI-DRY.jpg"),
        require("../assets/images/MdaliNizampura/MIX-VEG.jpg"),
      ],
      childImageNames: [
        "Butter Chicken",
        "Chicken Fried Rice",
        "Chicken Tandoori",
        "Dhaba Chilli Dry",
        "Mix Veg",
      ],
      info: "Pumpkin, sugar, butter, eggs",
      price: "$10.00",
    },
    {
      downloadLink: '#',
      image: require("../assets/images/menu/apsara-dhaba.jpg"),
      childImage: [
        require("../assets/images/md ali apsara/Chciken-Lapeta.jpg"),
        require("../assets/images/md ali apsara/Chicken-Nawabi-Handi.jpg"),
        require("../assets/images/md ali apsara/Dhaba-Chilli.jpg"),
        require("../assets/images/md ali apsara/Irani-dry.jpg"),
        require("../assets/images/md ali apsara/Maratha-kabab.jpg"),
      ],
      childImageNames: [
        "Chciken Lapeta",
        "Chicken Nawabi Handi",
        "Dhaba Chilli",
        "Irani Dry",
        "Maratha Kabab",
      ],

      name: "Md.Ali Apsara",
      info: "Chocolate, eggs, vanilla, milk",
      price: "$7.00",
    },
    {
      downloadLink: '#',
      image: require("../assets/images/menu/dilli-darbar.jpg"),
      childImage: [
        require("../assets/images/MdaliDilliDarbar/CHICKEN-MAHARAJA-GRAVY.jpg"),
        require("../assets/images/MdaliDilliDarbar/CHIKCKEN-BHUNA.jpg"),
        require("../assets/images/MdaliDilliDarbar/butter-chicken.jpg"),
        require("../assets/images/MdaliDilliDarbar/chicken-lamba.jpg"),
        require("../assets/images/MdaliDilliDarbar/chicken-noodles.jpg"),
      ],
      childImageNames: [
        "Chicken Maharaja Gravy",
        "Chicken Bhuna",
        "Butter Chicken",
        "Chicken Lamba",
        "Chicken Noodles",
      ],
      name: "Md.Ali Dilli Darabar",
      info: "Turkey, oregano, tomato paste, peppers",
      price: "$8.00",
    },
    {
      downloadLink: 'https://drive.google.com/file/d/1uNxIvvIOzpm2eNBZaYA5OLFQcpGwdY11/view?usp=drive_link',
      image: require("../assets/images/menu/dhamankar-naka-dhaba.jpg"),
      childImage: [
        require("../assets/images/Mohd Ali Dhamankar Naka/Chicken-dry-kabab.jpg"),
        require("../assets/images/Mohd Ali Dhamankar Naka/Chicken-Fried-rice.jpg"),
        require("../assets/images/Mohd Ali Dhamankar Naka/Chicken-Lapeta.jpg"),
        require("../assets/images/Mohd Ali Dhamankar Naka/Mutton-Kali-mirch.jpg"),
        require("../assets/images/Mohd Ali Dhamankar Naka/daal tadka.jpg"),
      ],
      childImageNames: [
        "Chicken Dry Kabab",
        "Chicken Fried Rice",
        "Chicken Lapeta",
        "Mutton Kali Mirch",
        "Daal Tadka",
      ],
      name: "Md.Ali Dhamankar Naka",
      info: "Cheese tortellini, sausage, garlic, carrots, zucchini",
      price: "$9.00",
    },
  ]);

  return (
    <div>
      <div className="container mt-5">
        <Row>
          {menucarddata.map((value, index) => {
            return (
              <>
                <Col md={6} lg={4} className="mt-3">
                  <div>
                    <div
                      className="gallery-img mx-auto"
                      onClick={() => handleCardClick(index)}
                    >
                      <div className="download-icon-container">
                        <a href={value.downloadLink} target="_blank">
                          <div className="download-icon"> <FontAwesomeIcon icon={faDownload} /></div>
                        </a>
                      </div>
                      <LazyLoadImage src={value.image} alt="" />
                      {/* <div className="image-heading">
                        <h4 text-align="center">{value.name}</h4>
                      </div> */}
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
      {modalOpen && selectedCardIndex !== null && (
        // <div className="child-modal" onClick={handleCloseModal}>
        //   <div className="child-modal-content" onClick={(e) => e.stopPropagation()}>
        //     {menucarddata[selectedCardIndex].childImage.map(
        //       (childImage, childIndex) => {
        //         console.log(childImage);
        //         return(
        //         <LazyLoadImage key={childIndex} src={childImage} alt={`Child ${childIndex + 1}`} />
        //         )
        //       }
        //     )}
        //   </div>
        // </div>
        //

        <Modal show={modalOpen} onHide={handleCloseModal} size="xl">
          <Modal.Header closeButton >
            <Modal.Title className="d-flex justify-content-center">
              {menucarddata[selectedCardIndex].name} Menu
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {menucarddata[selectedCardIndex].childImage.map(
                (childImage, childIndex) => (
                  <Col key={childIndex} xs={6} md={3} className="text-center">
                    <div
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "10px",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                    >
                      <LazyLoadImage
                        src={childImage}
                        alt={`Child ${childIndex + 1}`}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                      <p className="fw-bold">
                        {
                          menucarddata[selectedCardIndex].childImageNames[
                          childIndex
                          ]
                        }
                      </p>
                    </div>
                  </Col>
                )
              )}
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default MenuCardImage;
