import React, { useEffect } from "react";
import GlobalBannerImage from "../../components/GlobalComponents/GlobalBannerImage";
import MenuCardImage from "../../components/MenuCardImage";



const MenuPage = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
      useEffect(() => {
        scrollToTop();
      }, []);


    return (
        <>
        <div className="menu-container">
            <div className="menubannerimage">
                <GlobalBannerImage />
            </div>
           

            <div className="menu-card-image">
                <MenuCardImage/>
            </div>
        </div>
        </>
    )
};



export default MenuPage