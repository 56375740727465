import React, { useState } from 'react'

import { Col, Row } from 'react-bootstrap'

import { LazyLoadImage } from "react-lazy-load-image-component";



const ContactusCard = () => {

    const [branchesCard, setBranchesCard] = useState(
        [
            {
                image: require('../assets/images/Contactusimages/logo-02.png'),
                name: 'Mohammad Ali Bharat',
                address: 'Md. Ali Bharat Dhaba /md Ali Cream Corner.',
                contact: '8793948715',
                email: "mohdalioutlets@gmail.com",
                location: "https://www.google.com/maps/dir//Shop+No+2,+Nashik+Rd,+near+Bharat+Dhaba,+Chavindra,+Bhiwandi,+Sawandhe,+Maharashtra+421302/@19.3217348,73.0013875,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7bdc55457dc7d:0x999b60404700cb9!2m2!1d73.0837892!2d19.3217533?entry=ttu"
            },
            {
                image: require('../assets/images/Contactusimages/logo-dhaba/md-ali-nizampura.jpg'),
                name: 'Mohammad Ali Nizampura',
                address: 'Mohammed Ali Restaurant, Chand Tara Masjid Rd.',
                contact: '7744996104',
                email: "mohdalioutlets@gmail.com",
                location: "https://www.google.com/maps/dir/19.2943084,73.0509849/muhammad+ali+nizampura/@19.2994929,73.0461675,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be7bd3bb43c6553:0x1f7ab25c68298d2a!2m2!1d73.0624852!2d19.3041215?entry=ttu"
            },
            {
                image: require('../assets/images/Contactusimages/logo-dhaba/md-ali-apsara-min.jpg'),
                name: 'Mohammad Ali Apsara',
                address: "Mohammed Ali's Apsara Family Restaurant",
                contact: '9834427869',
                email: "mohdalioutlets@gmail.com",
                location: "https://www.google.com/maps/dir/19.2943084,73.0509849/muhammad+ali+apsara/@19.2912827,73.0406087,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be7bd9dc125d023:0x6511fb51433dd3d0!2m2!1d73.0712608!2d19.2857788?entry=ttu"
            },
            {
                image: require('../assets/images/Contactusimages/logo-05.png'),
                name: 'Mohammad Ali Dilli Darabar',
                address: 'Mohammed Ali Dilli Darbar Dhaba,',
                contact: '1-800-000-111',
                email: "mohdalioutlets@gmail.com",
                location: "https://www.google.com/maps/dir/19.2943106,73.0509952/mohammed+ali+dilli+darbar+dhaba/@19.3146205,73.0403816,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be79756b83d6599:0x63013fd0e2446dfd!2m2!1d73.1123839!2d19.3367673?entry=ttu"
            },
            {
                image: require('../assets/images/Contactusimages/logo-dhaba/md-ali-dhamankar-naka.jpg'),
                name: 'Mohammad Ali Dhamankar Naka',
                address: 'Mohammed Ali Dhaba, 421302, Dhamankar Naka,',
                contact: '7058565259',
                email: "mohdalioutlets@gmail.com",
                location: "https://www.google.com/maps/dir//421302,+Dhamankar+Naka,+Padma+Nagar,+Bhiwandi,+Maharashtra+421302/@19.2876233,72.9721704,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7bd08488f6c41:0x964bcd3f1b9983b9!2m2!1d73.054532!2d19.2876952?entry=ttu"
            },
        ]
    )



    return (
        <div>


            <Row>
                {
                    branchesCard.map((value) => {
                        return (
                            <>
                                <Col md={4}>
                                    <div className="details-place">
                                        <div className="place-image">
                                            <LazyLoadImage src={value.image} alt="" />
                                        </div>
                                        <div className="place-name mt-3">
                                            <h2 className='fw-bold fs-5'>{value.name}</h2>
                                        </div>
                                        <div className="place-address my-2">
                                            {value.address}
                                        </div>
                                        <div className="place-contact">
                                            <a className='text-center' href={`tel:${value.contact}`}>{value.contact} </a>
                                            / <span><a href={`mailto:${value.email}`} target='_blank'>
                                                {value.email}
                                            </a>
                                            </span>
                                        </div>
                                        <div className="get-direction-button mt-4 mb-5">
                                            <button><a href={value.location} target='_blank'> GET DIRECTIONS</a></button>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        )
                    })
                }

            </Row>

        </div>
    )
}

export default ContactusCard
