import React from "react";
import FooterComponent from "../../components/FooterComponent";
import { Col, Row } from "react-bootstrap";

import Teaimage from "../../assets/images/aboutus/tea-cup.jpg";
import Plateimage from "../../assets/images/aboutus/plates-dish.jpg";

import GlobalBannerImage from "../../components/GlobalComponents/GlobalBannerImage";

import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUsPage = () => {
  return (
    <>
      <div className="aboutus-container">
        <div className="header"></div>

        {/* //-----------------------   about us -------------------------------------------// */}

        <GlobalBannerImage />

        {/* /////-------------------------end about us----------------------------------// */}

        {/* ///---------------------------OUR HISTORY----------------------------------------------/// */}

        <div className="container">
          <div className="our-history mt-5 mb-5">
            <Row className="for-flex-history">
              <Col md={6} className="d-flex justify-content-end">
                <div className="tea-image">
                  <LazyLoadImage src={Teaimage} alt="" />
                </div>
              </Col>
              <Col md={6}>
                <div className="history-part ms-4 mt-3">
                  <h1 className="fw-bold" align='center'>OUR HISTORY</h1>
                  <p>
                  Welcome to <b> Mohd Ali's </b> , a culinary haven where passion meets palates. Our story is one of devotion to the art of food, rooted in a rich culinary heritage. At <b> Mohd Ali's </b>, we strive to create an unforgettable dining experience, blending tradition with innovation. From our kitchen to your table, every dish is crafted with love and precision, reflecting our commitment to excellence.
                  </p>
                </div>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
                <div className="value-part mt-5">
                  <h1 className="mt-4 fw-bold" align='center'>WHAT WE VALUE</h1>
                  <p>
                    <b>
                  [Mohammad Ali Dhaba] </b> stands on the pillars of integrity, sustainability, hospitality, innovation, inclusivity, and excellence. We prioritize locally sourced ingredients, foster community connections, embrace diverse tastes, and continually innovate our menu. Our commitment to these values defines the exceptional dining experience we offer.
                  </p>
                </div>
              </Col>


                {/* ////----------------------------------------Teams----------------------------------------------------//// */}

                {/* <div className="teams-container">
                    <div className="container">
                        <div className="meet-the-team ">
                            <div className="team-header-name">
                                <h1 className="fw-bold">MEET THE TEAM</h1>
                                <p className="mt-3">Delenit commune delectus cu has, labitur recteque in mel. Ad mea equidem similique, qui graeci nusquam suscipiantur in. Eu vidit dicta fuisset vis. Vix integre delicata ad, decore oportere aliquando est ad, vix nostro aliquip omnesque id. Qui te dissentiet dissentiunt, constituto elaboraret vim.</p>
                            </div>
                        </div>

                        <div>
                            <TeamCard />
                        </div>
                    </div>
                </div>              */}

                {/* <div className="container rating mt-5 bg-light">
                        <Row>
                            <Col md={6} lg={6}  >
                                <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                                    <div className="rates-info">
                                        <p>"You will be astonished that such a place is near dhamankar naka, it's shows a small entrance and then inside you will see big space for family and for normal.... talking about the food... Tasty food at such reasonable rate is something that will make you feel overwhelmed...i loved the kulcha here, it was really very tasty...and over all food is good...and ambience is also very good ....if you live near dhamankar naka....cheap good food is the option you will get here."</p>
                                    </div>
                                    <div className="name-rates">
                                        <h5>-Ankit Kumar singh-</h5>
                                    </div>
                                    <div className="star-rate">
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={6}>
                            <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                                    <div className="rates-info">
                                        <p>"One should try Chicken Lemon over here (not a dhaba style) but made by the chef in house.They have made the ambience like dhabba which is a good one. It is much more Cheaper than the dhaba. With a dhaba like feel.Situated in the city so easily accessible."</p>
                                    </div>
                                    <div className="name-rates">
                                        <h5>-azhar momin-</h5>
                                    </div>
                                    <div className="star-rate">
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={6}>
                            <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                                    <div className="rates-info">
                                        <p>"Experience the best non veg food with dhaba style. Unique interior design and will have the proper feel of dhaba. Tandoori n Pahadi kebab where the awesome dishes."</p>
                                    </div>
                                    <div className="name-rates">
                                        <h5>-Vinayak Merugu-</h5>
                                    </div>
                                    <div className="star-rate">
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={6}>
                            <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                                    <div className="rates-info">
                                        <p>"Ambience is good, prices are reasonable we get half chicken gravy lollipop & full chicken rice just in 350. Sofa seating available here"</p>
                                    </div>
                                    <div className="name-rates">
                                        <h5>-vivek nayak-</h5>
                                    </div>
                                    <div className="star-rate">
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                        <LazyLoadImage src={Starimage} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div> */}

              <Col md={6}>
                <div className="mt-5">
                  <div className="plate-image">
                    <LazyLoadImage src={Plateimage} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* //------------------------------rates -----------------------------------------// */}

        <div className="rate-restaurant">
          <div className="pizza-bg-image">
            <div className="container">
              <Row className=" max-height w-100  d-flex  justify-content-center align-items-center ">
                <Col md={4}>
                  <div className="high-restaurant text-light d-flex flex-column justify-content-center align-items-center">
                    <h1>10</h1>
                    <p>HIGH RESTAURANTS</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="michelin-star text-light d-flex flex-column justify-content-center align-items-center">
                    <h1>2</h1>
                    <p>MICHELIN STAR</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="employees text-light d-flex flex-column justify-content-center align-items-center">
                    <h1>150</h1>
                    <p>TALENTED EMPLOYEES</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* ////----------------------------------------Teams----------------------------------------------------//// */}
        

        {/* <div className="teams-container">
          <div className="container">
            <div className="meet-the-team ">
              <div className="team-header-name">
                <h1 className="fw-bold">MEET THE TEAM</h1>
                <p className="mt-3">
                  Delenit commune delectus cu has, labitur recteque in mel. Ad
                  mea equidem similique, qui graeci nusquam suscipiantur in. Eu
                  vidit dicta fuisset vis. Vix integre delicata ad, decore
                  oportere aliquando est ad, vix nostro aliquip omnesque id. Qui
                  te dissentiet dissentiunt, constituto elaboraret vim.
                </p>
              </div>
            </div>

            <div className="team-image-part">
              <TeamCard />
            </div>
          </div>

          <div className="rating-contain mt-5">
            <div className="container rating mt-5">
              <Row>
                <Col  md={6} lg={6} className=" for-width">
                  <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                    <div className="rates-info">
                      <p>
                        "Ferri altera eripuit ne nam. Duo at tibique
                        conclusionemque, nam modo ancillae tacimates et, cu vim
                        omnes phaedrum conclusionemque. Ex tempor eligendi
                        corrumpit vim, sea ei tacimates reformidans. Congue
                        corpora vis ad."
                      </p>
                    </div>
                    <div className="name-rates">
                      <h5>-Anna Jones-</h5>
                    </div>
                    <div className="star-rate">
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                    </div>
                  </div>
                </Col>
                <Col  md={6} lg={6}>
                  <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                    <div className="rates-info">
                      <p>
                        "Eam numquam aliquam ea, latine placerat omittantur pri
                        ea. Ius cu nobis numquam vivendo. Bonorum utroque has
                        et, nam cu case clita voluptaria. Est ne error laoreet,
                        te pro stet efficiantur suscipiantur, illum labitur
                        ullamcorper mea no."
                      </p>
                    </div>
                    <div className="name-rates">
                      <h5>-Louis Rios-</h5>
                    </div>
                    <div className="star-rate">
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                    </div>
                  </div>
                </Col>
                <Col  md={6} lg={6}>
                  <div className="card-rater d-flex flex-column justify-content-center align-items-center">
                    <div className="rates-info">
                      <p>
                        "Mel ea diceret eripuit, has alii solet scribentur ad,
                        vix bonorum tincidunt quaerendum ex. Odio clita has cu,
                        ex per movet malorum, no nusquam officiis nominati mea.
                        Ad graece admodum percipitur cum."
                      </p>
                    </div>
                    <div className="name-rates">
                      <h5>-Jean Carlson-</h5>
                    </div>
                    <div className="star-rate">
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                      <LazyLoadImage src={Starimage} alt="" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div> */}

        {/* ///--------------------------------------------------------------------------------------------------------/// */}
      </div>
    </>
  );
};

export default AboutUsPage;
