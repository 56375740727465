import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import MenuIcon from "../../assets/images/svg/hamburger-menu.svg";

const HeaderComponent = ({ offset }) => {
  const [openClosedToggel, setOpenClosedToggel] = useState(false);
  const ToggelVisibilty = () => {
    setOpenClosedToggel(!openClosedToggel);
  };

  const [pathname, setPathname] = useState("");
  const location = useLocation();
  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  const closeNavigation = () => {
    setOpenClosedToggel(false);
  };
  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 300;

      // Set isScrolled to true if scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);
    };
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; }, []);

  return (
    <>
      {/* <div className="main-container"> */}
      <header>
        <div className={
            pathname === "/" ? (isScrolled ? "header-with-scroll" : "header-without-scroll") : "header-with-scroll"
          }
          // className={
          //   pathname == "/"
          //     ? offset >= 300
          //       ? "header-with-scroll"
          //       : "header-without-scroll"
          //     : offset >= 250
          //     ? "header-with-scroll"
          //     : "header-without-scroll"
          // }
        >
          <div className="head-top pt-2 ps-3 pe-3 d-flex justify-content-between align-items-center">
            <div className="header-address ">
              <span>
                Nizampura, <br /> Bhiwandi 421302
              </span>
            </div>
            <div className="head-name">
              <div className="resto-logo head-logo text-white">
                <LazyLoadImage src={Logo} alt="" />
              </div>
            </div>
            <div className="cell">
              <span>
                Book Now <br />
                <a href="tel:+917744996104">+917744996104</a>
              </span>
            </div>
          </div>
          <div
            className={`d-flex navbar__respon justify-content-around align-items-center`}
          >
            <div className="nav">
              <ul>
                <li>
                  <Link
                    to={"/"}
                    onClick={() => handleButtonClick("Home")}
                    className={activeButton === "Home" ? "active" : ""}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/menu"}
                    onClick={() => handleButtonClick("Menu")}
                    className={activeButton === "Menu" ? "active" : ""}
                  >
                    Menu
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/about"}
                    onClick={() => handleButtonClick("About")}
                    className={activeButton === "About" ? "active" : ""}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/reservation"}
                    onClick={() => handleButtonClick("Reservation")}
                    className={activeButton === "Reservation" ? "active" : ""}
                  >
                    Reservation
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/services"}
                    onClick={() => handleButtonClick("Service")}
                    className={activeButton === "Service" ? "active" : ""}
                  >
                    Service
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact"}
                    onClick={() => handleButtonClick("Contact")}
                    className={activeButton === "Contact" ? "active" : ""}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="responsive-header">
          <div className="resto-logo left-head p-2">
            <LazyLoadImage src={Logo} alt="" />
          </div>
          <button
            className={`right-head hamburger ${
              openClosedToggel ? "is-active" : ""
            }`}
            onClick={ToggelVisibilty}
          >
            <div className="bar"></div>
          </button>
          <ul className={`mobiles  ${openClosedToggel ? "nav-links" : ""}`}>
            <li>
              <Link to={"/"} onClick={closeNavigation}>
                Home
              </Link>
            </li>
            <li>
              <Link to={"/menu"} onClick={closeNavigation}>
                Menu
              </Link>
            </li>
            <li>
              <Link to={"/about"} onClick={closeNavigation}>
                About Us
              </Link>
            </li>
            <li>
              <Link to={"/reservation"} onClick={closeNavigation}>
                Reservation
              </Link>
            </li>
            <li>
              <Link to={"/services"} onClick={closeNavigation}>
                Service
              </Link>
            </li>
            {/* <li>
              <Link to={"/gallery"}>Gallery</Link>
            </li> */}
            {/* <li>
              <Link to={"/blog"}>Blog</Link>
            </li> */}
            <li>
              <Link to={"/contact"} onClick={closeNavigation}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default HeaderComponent;
