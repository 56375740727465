import React, { useEffect, useState } from "react";
// import DatePicker from "react-date-picker";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import GlobalBannerImage from "../../components/GlobalComponents/GlobalBannerImage";
import DatePicker from "react-date-picker";

const ReservationPage = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);




  const [mail, setMail] = useState("");
  const [totalPerson, setTotalPerson] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [mobileNaumber, setMobileNaumber] = useState("");
  const [bookingTime, setBookingTime] = useState("10:00");
  const [bookingDate, setBookingDate] = useState(new Date());

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);

    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      setBookingTime(
        "Invalid time format. Please enter a valid 24-hour time (e.g., 10:00)"
      );
      return;
    }

    let period = "AM";
    let hours12 = hours;
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours12 = hours - 12;
      }
    }
    if (hours === 0) {
      hours12 = 12;
    }

    const time12 = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return time12;
  };
  const [isLoading, setIsLoading] = useState(false);

  const addFoodBooking = () => {
    setIsLoading(true);
    if (
      !customerName.trim() ||
      !totalPerson.trim() ||
      !mail.trim() ||
      mobileNaumber.length < 10 ||
      bookingDate === null ||
      !bookingTime.trim()
    ) {
      Swal.fire(
        "Error",
        `${mobileNaumber && mobileNaumber.length < 10
          ? "Mobile number must be at least 10 characters long"
          : "Please fill in all fields"
        }`,
        "error"
      );
      setIsLoading(false);
      return;
    }
    const emailBody = `<html>
    <body>
      <h3>Table Booking Request Details:</h3>
      <p><strong>Customer Name: </strong>${customerName}</p>
      <p><strong>Email: </strong>${mail}</p>
      <p><strong>Contact Number: </strong>${mobileNaumber}</p>
      <p><strong>Total Person: </strong>${totalPerson}</p>
      <p><strong>Booking Date: </strong>${moment(bookingDate).format("LL")}</p>
      <p><strong>Booking Time: </strong>${convertTo12HourFormat(
      bookingTime
    )}</p>
    </body>
  </html>`;

    const sentFrom = mail; // Replace with your email address
    const sentName = customerName;
    const subject =
      "Table booking request information from Bhaijaan Dhaba website";
    const dataString =
      "emailBody=" +
      encodeURIComponent(emailBody) +
      "&sentFrom=" +
      encodeURIComponent(sentFrom) +
      "&sentName=" +
      encodeURIComponent(sentName) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&sendmail=";

    axios
      .post("https://mdalidhaba.in/php_mailer/phpmailsend.php", dataString, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("email response", response.data);
        setIsLoading(false);
        if (response.data === "success") {
          Swal.fire("Success", "Email sent", "success");
          setCustomerName("");
          setMobileNaumber("");
          setTotalPerson("");
          setMail("");
          setBookingTime("10:00");
          setBookingDate(new Date());
          document.querySelector('.spl-requests textarea').value = '';
        } else {
          Swal.fire("Error", "Failed to send email", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        Swal.fire(
          "Error",
          "An error occurred while sending the email",
          "error"
        );
      });

    console.log("correct");
    // return true;
  };
  const handleBookingDate = (val) => {
    setBookingDate(val);
  };



  return (
    <>
      <div className="reservation-container">
        <GlobalBannerImage />
      </div>

      <div className="container reservation-form mt-5">
        <div className="form-heading pt-3">
          <h1>MAKE A RESERVATION</h1>
          <p>
            You can make a reservation any time online or by calling
            <span className="place-contact">
              <a className='text-center' href={`tel:7744996104`}>7744996104 </a>
            </span>
            during our opening hours:
          </p>
        </div>
        <div className="form me-5 ms-5">
          <Row>
            <Col md={4} className="mt-5">
              <div className="input-field">
                <label htmlFor="">Name*</label>
                <input
                  type="text"
                  placeholder=""
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="input-field mt-5">
                <label htmlFor="">Email*</label>
                <input
                  type="email"
                  placeholder=""
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4} >
              <div className="input-field mt-5">
                <label htmlFor="">Phone*</label>
                <input
                  type="call"
                  placeholder=""
                  value={mobileNaumber}
                  onChange={(e) =>
                    setMobileNaumber(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="input-picker mt-5">
                <label htmlFor="">Date*</label>
                <DatePicker
                  onChange={(value) => handleBookingDate(value)}
                  value={bookingDate}
                  format={"dd:MM:y"}
                  minDate={new Date()}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="input-field mt-5">
                <label htmlFor="">Time*</label>
                <input
                  className="clock-icon-reserv"
                  type="time"
                  placeholder=""
                  value={bookingTime}
                  onChange={(e) => setBookingTime(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="input-field mt-5">
                <label htmlFor="">Seat*</label>
                <input
                  type="seat"
                  placeholder=""
                  value={totalPerson}
                  onChange={(e) =>
                    setTotalPerson(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
              </div>
            </Col>
            <Col>
              <div className="spl-requests input-field mt-5">
                <label htmlFor="">Special Requests</label>
                {/* <input type="text" name="" id="" /> */}
                <textarea name="" id="" cols="30" rows="5"></textarea>
              </div>
            </Col>
          </Row>
        </div>
        <div className="reserve-book-btn mt-5">
          {isLoading ? (
            <div className="loading">
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          ) : (
            <button onClick={addFoodBooking}>BOOK NOW</button>
          )}
          {/* <button>BOOK NOW</button> */}
        </div>
      </div>
    </>
  );
};

export default ReservationPage;
