import React from 'react';
import { Col, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
//  SVG ---//
import FacebookSvg from '../../assets/images/svg/social/facebook.svg'
import TwitterSvg from '../../assets/images/svg/social/twitter.svg'
import InstagramSvg from '../../assets/images/svg/social/instagram.svg'
import WhatsappSvg from '../../assets/images/svg/social/whatsapp.svg'
// Images--------//
import DishImage1 from '../../assets/images/Food/Desserts/chocolate-cupcakes-2.jpg'
import DishImage2 from '../../assets/images/Food/Main_Cources/coconut-curry-fried-chicken-3.jpg'
import DishImage3 from '../../assets/images/Food/Main_Cources/roast-chicken-2.jpg'
import DishImage4 from '../../assets/images/Food/Main_Cources/sriracha-beef-skewers-1.jpg'
import DishImage5 from '../../assets/images/Food/baked-potato-skins-2.jpg'
import DishImage6 from '../../assets/images/Food/Soups/terrific-turkey-chili-2.jpg'
import DishImage7 from '../../assets/images/Food/Soups/creamy-chicken-wild-rice-soup-2.jpg'
import DishImage8 from '../../assets/images/Food/Soups/italian-sausage-tortellini-soup-2.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component";

const FooterComponent = () => {

	return (
		<>
			<div className="container">

				<div className=" sizing__text">
					<Row>
						<Col md={4}>
							<div className="address">
								<h3>ADDRESS:</h3>
								<span>Mohammad Ali Restaurent</span>
								<span>Mohammed Ali restaurant, Chand Tara Masjid Rd.</span>
							</div>
						</Col>
						<Col md={4}>
							<div className="reservation">
								<h3>RESERVATION:</h3>
								<span className='place-contact'><a href="tel:7744996104">7744996104</a></span>
								<span className='place-contact' ><a href='mailto:mohdalioutlets@gmail.com' target='_blank'>mohdalioutlets@gmail.com</a></span>
							</div>
						</Col>
						<Col md={4}>
							<div className="hours">
								<h3>OPEN HOURS:</h3>
								<span>Monday - Friday: 6 PM - 4 AM</span>
								<span>Saturday - Sunday: 6 PM - 4 AM</span>
							</div>
						</Col>
					</Row>

				</div>

				<div className="social-contact">
					{/* <div className="icons-social">
						<LazyLoadImage src={FacebookSvg} alt="error" />
						<LazyLoadImage src={TwitterSvg} alt="error" />
						<LazyLoadImage src={InstagramSvg} alt="error" />
						<LazyLoadImage src={WhatsappSvg} alt="error" />
					</div> */}
					<div className="designer-name mt-3">
						<p>© Mohammad Ali Dhaba © 2024, All Right Reserved. Designed By <a className='companyname' href='http://www.crisfooddigiverse.com/'> Crisfood Digiverse.</a></p>
					</div>

				</div>
			</div>
			<div className="food-image">

				<div className="all-dishes">
					<LazyLoadImage src={DishImage1} alt="error" />
				</div>

				<div className="all-dishes">
					<LazyLoadImage src={DishImage2} alt="error" />
				</div>

				<div className="all-dishes">
					<LazyLoadImage src={DishImage3} alt="error" />
				</div>

				<div className="all-dishes image-none-here">
					<LazyLoadImage src={DishImage4} alt="error" />
				</div>

				<div className="all-dishes image-none-here">
					<LazyLoadImage src={DishImage5} alt="error" />
				</div>

				<div className="all-dishes image-none-here">
					<LazyLoadImage src={DishImage6} alt="error" />
				</div>

				<div className="all-dishes image-none-here">
					<LazyLoadImage src={DishImage7} alt="error" />
				</div>

				<div className="all-dishes image-none-here">
					<LazyLoadImage src={DishImage8} alt="error" />
				</div>
			</div>


		</>
	)
}

export default FooterComponent