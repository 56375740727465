import React, { useEffect, useState } from "react";
import GlobalBannerImage from "../../components/GlobalComponents/GlobalBannerImage";
import ContactusCard from "../../components/ContactusCard";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUsPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [mess, setMess] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    if (
      name.trim() === "" ||
      mail.trim() === "" ||
      phone.length < 10 ||
      mess.trim() === ""
    ) {
      Swal.fire(
        "Error",
        `${phone && phone.length < 10
          ? "Mobile number must be at least 10 characters long"
          : "Please fill in all fields"
        }`,
        "error"
      );
      setIsLoading(false);
      return;
    }

    const emailBody = `<html>
          <body>
            <h3>Contact Request Details:</h3>
            <p><strong>Name: </strong>${name}</p>
            <p><strong>Contact No.: </strong>${phone}</p>
            <p><strong>Email: </strong>${mail}</p>
            <p><strong>Message: </strong>${mess}</p>
          </body>
        </html>`;

    const sentFrom = mail; // Replace with your email address
    const sentName = name;
    const subject = "Contact request information from Bhaijaan Dhaba website";
    const dataString =
      "emailBody=" +
      encodeURIComponent(emailBody) +
      "&sentFrom=" +
      encodeURIComponent(sentFrom) +
      "&sentName=" +
      encodeURIComponent(sentName) +
      "&subject=" +
      encodeURIComponent(subject) +
      "&sendmail=";

    axios
      .post("https://mdalidhaba.in/php_mailer/phpmailsend.php", dataString, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log("email response", response.data);
        setIsLoading(false);
        if (response.data === "success") {
          Swal.fire("Success", "Email sent", "success");
          setName("");
          setMail("");
          setPhone("");
          setMess("");
        } else {
          Swal.fire("Error", "Failed to send email", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        Swal.fire(
          "Error",
          "An error occurred while sending the email",
          "error"
        );
      });
    console.log("correct");
    // return true;
  };
  return (
    <>
      <div className="contact-us-page">
        {/* /////----------------contact us banner------------------------/////// */}

        <div className="contact-us-banner">
          <GlobalBannerImage></GlobalBannerImage>
        </div>

        <div className="container contact-us mt-5">
          <div className="place-directions">
            <ContactusCard />
          </div>

          <div className="mx-auto form-table-here">
            <Row>
              <Col md={12} lg={12} xl={6}>
                <div className="input-width">
                  <div className="form-lastclass">
                  <div>
                  <h5 className="heading_width">Name*</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                    </div>
                    <div>
                  <h5 className="heading_width email">Email*</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    />
                    </div>
                    </div>
                  <h5 className="heading_width">Phone Number*</h5>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    className="form-control mb-3"
                    value={phone}
                    onChange={(e) =>
                      setPhone(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    placeholder="Phone Number"
                  />
                  <h5>Message*</h5>
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    value={mess}
                    onChange={(e) => setMess(e.target.value)}
                  ></textarea>
                </div>
                <div className="  send-message-button mt-5 mx-auto">
                  {isLoading ? (
                    <div className="loading">
                      <span>L</span>
                      <span>O</span>
                      <span>A</span>
                      <span>D</span>
                      <span>I</span>
                      <span>N</span>
                      <span>G</span>
                    </div>
                  ) : (
                    <button onClick={handleSubmit}>SEND MESSAGE</button>
                  )}
                  {/* <button>SEND MESSAGE</button> */}
                </div>
                {/* <div className="mt-5">
                  <div className="name-contact">
                  <h5 className="heading_width">Name*</h5>
                  <input type="text" className="form-control" placeholder="" />
                  </div>
                  <div className="email-contact">
                  <h5 className="heading_width" >Email*</h5>
                  <input type="text" className="form-control" placeholder="" />
                  </div>
                  </div>
                  <div className="name-email subject-contact mt-5">
                  <h5>Subject*</h5>
                  <input type="text" className="form-control" placeholder="" />
                  </div>
                  <div className="name-email message-contact mt-5">
                  <h5>Message*</h5>
                  <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div className="name-email send-message-button mt-5">
                  <button>SEND MESSAGE</button>
                </div> */}
              </Col>
              <Col md={12} lg={12} xl={6}>
                <iframe
                  src="https://www.google.com/maps/d/embed?mid=1Hm1SETrvMaWw6YMbMa0detG-xRjxxaE&ehbc=2E312F"
                  width="100%"
                  height="450"
                  style={{ border: 0, marginTop: "40px" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
